export default [
  {
    route: "credit-experts-ncr-programs",
    name: "NCR",
    modul: 6,
  },
  {
    route: "credit-experts-ncr-programs-return",
    name: "RETURNED BY ADM",
    modul: 6,
  },
  {
    //route: "ncr-programs",
    route: "specialist-ncr-programs",
    name: "NCR",
    modul: 5,
  },
  {
    //route: "ncr-programs-return",
    route: "specialist-ncr-programs-return",
    name: "RETURNED BY ADM",
    modul: 5,
  },
  {
    route: "bc-ncr-programs",
    name: "NCR",
    modul: 7,
  },
  {
    route: "bc-ncr-programs-return",
    name: "RETURNED BY ADM",
    modul: 7,
  },
  {
    route: "connection-ncr-programs-table",
    name: "NCR",
    modul: 20,
  },
  {
    route: "connection-ncr-programs-table-return",
    name: "RETURNED BY ADM",
    modul: 20,
  },
  {
    route: "ncr-programs-cdigital",
    name: "NCR",
    modul: 22,
  },
  {
    route: "ncr-programs-cdigital-return",
    name: "RETURNED BY ADM",
    modul: 22,
  },
]