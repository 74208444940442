<template>
  <b-modal
    v-model="modalCenter"
    size="lg"
    no-close-on-backdrop
    modal-class="modal-primary"
    title="Create NCR"
    title-tag="h3"
    @hidden="hideModal"
  >
    <b-container>
      <template>
        <!-- BODY -->
        <ValidationObserver ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2">
            <basic-information-ncr-programs
              ref="refBasicInformationNcrPrograms"
              :state-table-card="false"
              :client="client"
            />
            <!-- :type-form="newLead" -->
          </b-form>
        </ValidationObserver>
      </template>
    </b-container>
    <template #modal-footer>
      <b-button @click="hideModal">Cancel</b-button>
      <b-button @click="onSubmit" variant="primary">
        <span v-if="submitBusy" class="spinner-border spinner-border-sm" />
        <span v-else> Save </span>
      </b-button>
    </template>
    <ModalApproveSupervisor
      v-if="activeModalApproveSupervisor"
      :modalApproveSupervisor="activeModalApproveSupervisor"
      @closeModalApprove="closeModalApprove"
      @approveSup="approveSup"
    />
  </b-modal>
</template>
<script>
// SERVICES
import ConnectionUsersRelationshipService from "@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service";
import ClientsConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

import { mapGetters, mapActions } from "vuex";
// COMPONENTS
import BasicInformationNcrPrograms from "./BasicInformationNcrPrograms.vue";
import NcrProgramsService from "../../../service/ncr-programs.service";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";

import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ButtonSave,
    BasicInformationNcrPrograms,
    NcrProgramsService,
    ValidationObserver,
    ModalApproveSupervisor,
  },
  data() {
    return {
      modalCenter: true,
      client: {
        account: "",
        card: 0,
        name: "",
        obs: "",
        option: "",
        others: "",
        typencr: null,
      },
      clientAgentAdministratorRelation: null,
      activeModalApproveSupervisor: false,
      ncrZeroWasApproved: false,
      submitBusy: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CLIENT_FROM_STORE: "DebtSolutionClients/G_CLIENTS",
    }),
    currentModuleId() {
      return this.$route.matched[0].meta.module;
    },
    isInConnectionModule() {
      return this.currentModuleId === 20;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreditExpertsProgram() {
      return [6, 20].some((module) => module == this.currentModuleId);
    },
    ncrParams() {
      return {
        account: this.client.account,
        card: this.client.card,
        id: "",
        module: this.moduleId,
        obs: this.client.obs,
        option: this.client.option.includes("others")
          ? this.client.others
          : this.client.option,
        session: this.currentUser.user_id,
        typencr: 1, //  this.client.typencr ? this.client.typencr : 1,
        user_module: this.moduleId,
        connection_administrator: this.isInConnectionModule
          ? this.clientAgentAdministratorRelation
          : null,
      };
    },
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE_NCR_PROGRAMS:
        "DebtSolutionNcrPrograms/A_UPDATE_TABLE_NCR_PROGRAMS",
    }),
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async onSubmit() {
      this.submitBusy = true;
      if (this.isInConnectionModule) {
        if (!this.CLIENT_FROM_STORE.advisor_connection_id) {
          this.showInfoSwal(
            "This client doesn't have an agent assigned, please set one or contact your chief."
          );
          this.submitBusy = false;
          return;
        } else {
          const params = {
            agent_id: this.CLIENT_FROM_STORE.advisor_connection_id,
          };
          const { status: hasAdministratorSpecialistRelationship } =
            await ConnectionUsersRelationshipService.checkIfAgentHasRelationShip(
              params
            );
          if (!hasAdministratorSpecialistRelationship) {
            this.showInfoSwal(
              "This client have an agent assigned who doesn't have a relationship with an administrator or specialist, please set one or contact your chief."
            );
            this.submitBusy = false;
            return;
          } else {
            const { data } =
              await ClientsConnectionService.getAgentAdminSpecialistRelation({
                agent_id: this.CLIENT_FROM_STORE.advisor_connection_id,
              });
            this.clientAgentAdministratorRelation = data[0].admin_id;
            this.submitBusy = false;
          }
        }
      }
      const isOtherOption =
        this.$refs.refBasicInformationNcrPrograms.stateOthers;

      this.$refs.refBasicInformationNcrPrograms.setTrueSaveAmount();

      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success && parseFloat(this.ncrParams.option) >= 0) {
          if (!this.isCreditExpertsProgram) await this.insertNcr();

          if (
            (this.isAgent || this.isAdvisorB) &&
            parseFloat(this.ncrParams.option) < 1
          )
            this.openModalApprove();
          else await this.insertNcr();

          this.submitBusy = false;
        } else {
          this.submitBusy = false;
          return;
        }
      });
    },
    async insertNcr() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();

        if (!isConfirmed) return;

        this.addPreloader();

        let result = await NcrProgramsService.insertNcr(this.ncrParams);

        this.A_UPDATE_TABLE_NCR_PROGRAMS(true);

        this.$emit("hideModal");
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Satisfactory action."
        );
        setTimeout(() => {
          this.A_UPDATE_TABLE_NCR_PROGRAMS(false);
        }, 3000);
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      } finally {
        this.removePreloader();
      }
    },
    openModalApprove() {
      this.activeModalApproveSupervisor = true;
    },
    closeModalApprove() {
      this.activeModalApproveSupervisor = false;
    },
    approveSup() {
      this.ncrZeroWasApproved = true;

      this.insertNcr();
    },
  },
};
</script>
<style></style>
