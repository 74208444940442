<template>
  <b-container>
    <b-row>
      <b-col>
        <validation-provider
          v-slot="{ errors }"
          name="account"
          rules="required"
        >
          <b-input-group class="mr-1">
              <!-- id="account" -->
            <b-form-input
              v-model="client.account"
              placeholder="Account"
              trim
              :state="errors[0] ? false : null"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="!client.account"
                @click="searchClient"
              >
                <span
                  v-if="isLoading"
                  class="spinner-border spinner-border-sm"
                />
                <span v-else class="visually-hidden">Search</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="py-1">
      <b-col v-if="errorSearchClient" class="position-absolute">
        <feather-icon
          icon="AlertCircleIcon"
          size="15"
          class="mr-50 text-danger"
        />
        <span class="text-danger">ACCOUNT NOT FOUND</span>
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col>
        <div class="d-flex justify-content-start">
          <p class="paddingX paddingY my-0 bg-primary rounded text-white fontW">
            CLIENT
          </p>
          <b-form-input
            id="account"
            v-model="client.name"
            disabled
            trim
            maxlength="14"
            class="w-100"
          />
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-1 rounded">
      <b-col cols="9">
        <div class="d-flex justify-content-start ">
          <p class="px-3 paddingY bg-primary bordeL text-white fontW my-0">
            AMOUNT
          </p>
           <!-- <b-form-group > -->
              <b-form-radio-group
                class="align-self-center pl-2 border border-1 w-100 px-2 py-1"
                v-model="client.option"
                :options="options"
              />
            <!-- </b-form-group> -->

          <!-- <b-form-group v-slot="{ ariaDescribedby }" class="w-100 m-0" required>
            <div
              :class="
                client.option === '' && stateOthers === false && onSaveAmount
                  ? 'border-danger'
                  : 'border-light'
              "
              class="d-flex justify-content-around my-0 border bordeR paddingY"
            >
              <b-form-radio
                v-model="client.option"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="0.00"
                @change="others(false)"
                >$ 0.00</b-form-radio
              >
              <b-form-radio
                v-model="client.option"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="18.99"
                @change="others(false)"
                >$ 18.99</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="client.others"
                @change="others(true)"
                >Others</b-form-radio
              >
            </div>
          </b-form-group> -->
        </div>
      </b-col>

      <b-col v-if="stateOthers" cols="3" class="d-flex justify-content-end">
        <b-input-group prepend="$" class="inputOthers">
          <money v-model="client.others" class="form-control" v-bind="vMoney" />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Teble Card -->
    <b-row v-if="showCards && cards" class="mt-1 fontW">
      <b-col>
        <credit-card-table
          :cards="cards"
          :optionName="client.option"
          @cardId="cardId"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1 fontW">
      <b-col>
        <p
          class="text-center py-1 bg-primary text-white rounded-top m-0"
          style="width: 137px"
        >
          OBSERVATION
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-center">
          <b-form-textarea v-model="client.obs" rows="4" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import { VMoney } from "v-money";
import NcrProgramsService from "../../../service/ncr-programs.service";
import CreditCardTable from "../../table/card/CreditCardTable.vue";

export default {
  components: {
    CreditCardTable,
    ValidationObserver,
  },
  directives: { money: VMoney },
  props: {
    stateTableCard: {
      type: Boolean,
    },
    client: {
      type: Object,
    },
  },
  data() {
    return {
      showCards: this.stateTableCard,
      isLoading: false,
      errorSearchClient: false,
      stateOthers: false,
      cards: {},
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        min: 0.0,
      },
      onSaveAmount: false,
      options:[
        {
          text: '$ 0.00',
          value: "0",
          isOther: false
        },
        {
          text: '$ 18.99',
          value: "18.99",
          isOther: false
        },
        {
          text: 'others',
          value: 'others',
          isOther: true
        }
      ],
    };
  },
  computed: {
    programId() {
      switch (parseInt(this.moduleId)) {
        // debt solution
        case 5:
          return 4;
          break;
        // credit experts
        case 6:
          return 3;
          break;
        // credit experts digital
        case 22:
          return 3;
          break;
        // Boost credit
        case 7:
          return 2;
          break;
        case 20:
          return 3;
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      A_SET_CLIENT: "DebtSolutionClients/A_GET_CLIENTS"
    }),
    cardId(data) {
      this.client.card = data.id;
      this.client.typencr = data.type_card;
    },
    setTrueSaveAmount() {
      this.onSaveAmount = true;
    },
    /* others(value) {
      console.log("🚀 ~ file: BasicInformationNcrPrograms.vue:219 ~ others ~ value", value)
      this.stateOthers = value;
      this.onSaveAmount = false;
    }, */
    loading(value) {
      this.isLoading = value;
    },
    async searchClient() {
      this.loading(true);
      const result = await NcrProgramsService.searchClientNcr({
        account: this.client.account,
        session: this.currentUser.user_id,
        program: this.programId,
        role: this.currentUser.role_id,
      });
      if (result.length > 0) {
        this.cards = JSON.parse(result[0].cards);
        this.client.name = result[0].name_client;
        this.showCards = true;
        this.errorSearchClient = false;
        if( this.moduleId === 20 ) {
          await this.A_SET_CLIENT({ id: result[0].client_account_id })
        }
        this.loading(false);
      } else {
        this.errorSearchClient = true;
        this.loading(false);
      }
    },
  },
  watch:{
    'client.option'(newV,oldV){
        const {isOther} = this.options.find( el => el.value == newV)

        this.stateOthers = isOther;
        this.onSaveAmount = false;
    }
  }
};
</script>
<style scoped>
.paddingY {
  padding-bottom: 8px;
  padding-top: 8px;
}
.paddingX {
  padding-left: 45px;
  padding-right: 45px;
}
.marginR {
  margin-right: 10px;
}
.bordeR {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bordeL {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fontW {
  font-weight: bold;
}
.inputOthers {
  height: 36px;
}
</style>
