<template>
  <div>
    <header-slot v-if="currentUser.modul_id !== 5">
      <template #actions>
        <div>
          <b-button
            variant="success"
            class="mr-1"
            @click="openModalCreateNcr = true"
          >
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="mr-50 text-white"
            />Create
          </b-button>
        </div>
      </template>
    </header-slot>
    <div v-else class="d-flex justify-content-end">
      <b-button
        variant="success"
        class="mr-1"
        @click="openModalCreateNcr = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />Create
      </b-button>
    </div>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in ncrTabs"
        :key="index"
        :to="{ name: item.route }"
        exact
        exact-active-class="active"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
        :class="
          counters.length > 0 && counters[index] > 0 && index != 3
            ? 'tab-count-nav-closed'
            : ''
        "
      >
        {{ item.name }}
        <!-- <b-badge
					pill
					variant="danger"
					class="ml-1"
					v-if="
						counters.length > 0 && counters[index] > 0 && index != 3
					"
					>{{ counters[index] }}</b-badge
				> -->
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-3 border-table-radius px-0"
      :class="
        currentUser.modul_id !== 5 ? 'border-top-primary' : 'border-top-info'
      "
    >
      <router-view :key="$route.name" />
    </b-card>

    <modal-create-ncr
      v-if="openModalCreateNcr"
      @hideModal="openModalCreateNcr = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalCreateNcr from "./components/modal/create-ncr/ModalCreateNcr.vue";
import TabsData from "@/views/commons/components/ncr-programs/ncr.tabs.js";
import NcrProgramsService from "./service/ncr-programs.service";

export default {
  components: {
    ModalCreateNcr,
  },
  data() {
    return {
      openModalCreateNcr: false,
      tabs: TabsData,
      counters: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    ncrTabs() {
      return this.tabs.filter((tab) => tab.modul == this.moduleId);
    },
    moduleId() {
      return this.$route.meta.moduleId;
    },
  },
  methods: {
    async getCounters() {
      try {
        const response = await NcrProgramsService.getCounterNcrPrograns({
          user_id: this.currentUser.user_id,
          role_id: this.currentUser.role_id,
          module_id: this.moduleId,
        });
        if (response.status == 200) {
          this.counters = response.data;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  async created() {
    await this.getCounters();
  },
};
</script>
<style lang=""></style>
